.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-prev:focus {
  font-size: inherit;
  left: 10px;
  z-index: 2;
  color: blueviolet;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-next:focus {
  font-size: inherit;
  right: 10px;
  z-index: 2;
  color: blueviolet;
}

.ant-drawer-header , .ant-drawer-footer{
  background: #F4F3F3;
}

.divider-vertical-drawer {
  height: auto;
  margin-left: 20px;
  margin-right: 20px;
}

.ant-drawer-close{
display: none !important;
}

.ant-tree-select-dropdown .ant-select-tree-checkbox{
  align-self: baseline !important;
  margin-top: 6px !important;
}

