.sub-header {
  font-size: 24px;
  font-style: normal;
  font-weight: bold;
  padding-left: 0;
  float: left;
}

.ant-select-selector {
  height: 40px !important;
  border-radius: 0 !important;
}

.ant-select-selection-placeholder {
  text-align: left !important;
}

.divider-vertical {
  float: left;
  margin-left: 7px;
  height: 36px;
  margin-right: 5px;
  margin-top: 3px;
}

.tags-label {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;

}

.ant-drawer {
  z-index: 997;
}