.ant-collapse-header-text {
  flex: none !important;
  font-weight: 600;
}

.ant-table-thead .ant-table-cell {
  background-color: white !important;
  border-radius: 0px;
}

.ant-collapse {
  background-color: #f5f5f5 !important;
  border-radius: 0px;
  padding: 16;
}

.catDesc {
  // margin-left: 100px;
  font-weight: 400;
  width: 45%;
  text-align: left;
  color: #716e79 !important;
}

.question-list-table.ant-table-wrapper .ant-table-tbody > tr > td {
  vertical-align: top;
}

.question-list-table .ant-table th.ant-table-cell {
  border-bottom: 1px solid #9c9aa1 !important;
  padding: 16px 24px 16px 24px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.ant-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}

.catName {
  text-align: left;
  min-width: 25%;
  max-width: 25%;
  color: #454250;
}

.row-highlight {
  background-color: #e5f6f6 !important;
  transition-duration: 1000ms;
  -webkit-transition-duration: 1000ms;
  -webkit-transition: background-color 1000ms linear;
  -moz-transition: background-color 1000ms linear;
  -o-transition: background-color 1000ms linear;
  -ms-transition: background-color 1000ms linear;
  transition: background-color 1000ms linear;

  .ant-table-cell {
    background-color: #e5f6f6 !important;
  }
}

.ant-collapse-header-text {
  width: 90%;
}

.view-response-anchor {
  color:  #2d8b93;
  //font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 19.6px */
  letter-spacing: -0.103px;
}
