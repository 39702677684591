.modal-share {
  margin-top: 120px;
  width: 450px !important;
}

.email-label {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10;
}

.ant-modal-title {
  font-weight: bold !important;
}

#modal-label label {
  font-size: 16px;
  font-weight: 600;
  color: #454250;
}
