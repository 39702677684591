// ::-webkit-scrollbar {
//   display: none;
// }

.ant-progress {
  display: contents !important;
  //padding-left: 10;
}
 .disease-table .ant-table-cell-with-append{
  display: flex !important;
}
.card-header {
  float: left;
  margin-bottom: 20px;
}

.card-header-funnel {
  text-align: left;
  margin-bottom: 20px;
}

.funnel-table .ant-table {
  border-left: 1px solid #9C9AA1;
  border-right: 1px solid #9C9AA1;
  border-radius: 0px !important;
}

.funnel-table .ant-table th.ant-table-cell {
  border-bottom: 1px solid #9C9AA1 !important;
}

.funnel-table-div .ant-table-wrapper .ant-table-tbody >tr:nth-child(n+2) {
  height: 55px !important;
}
.custom-icon-right-outlined:hover,
.custom-icon-left-outlined:hover {
  padding: 18px 7px !important;
  background-color: #27a6a4;
  border-radius: 2px;
  color: white;
}

.custom-icon-right-outlined,
.custom-icon-left-outlined {
  padding: 18px 7px !important;
  border: 1px solid lightgray;
  background-color: white;
  border-radius: 2px;
  color: grey;
  z-index: 10,
}

.no-spin .ant-input-number-handler-wrap {
  display: none;
}