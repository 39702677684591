#cardId .ant-card-head-title {
  flex: none !important;
}

.country-field {
  width: 32%;

  :after {
    clear: "";
  }
  margin-left: 1%;
  border-left: solid;
  border-width: 0 0 0 1px;
  border-color: #dedcde;

  .globe-icon {
    width: 3%;
    margin-left: 1%;
    text-align: left;
    float: left;
  }

  .card-item-text {
    font-size: 16px;
    font-weight: 400px;
    color: "#454250";
    width: 90%;
    margin-left: 5%;
    text-align: left;
    float: left;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
}

.card-item-text-description {
  font-size: 16px;
  font-weight: 400px;
  color: "#454250";
  // width: 90%; margin-left: 5%; text-align: left; float: left;
  word-wrap: break-word;
  overflow-wrap: break-word;
  display: inline;
  width: 95%;
  text-align: left;
}

.modal-delete {
  margin-top: 120px;
  width: 450px !important;
}

.ant-modal .ant-modal-content,
.ant-btn {
  border-radius: 0px;
}

.ant-card-body {
  padding: 0px !important;
}

.card-row-1 {
  background: #fafafa;
  padding: 12px;
}

.horizontal-border {
  border-left: solid;
  border-width: 0 0 0 1px;
  border-color: #dedcde;
}

#description-overflow {
  overflow: hidden;
  // width: 582px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.tooltip-class {
  width: 400px;
}

.badge-class {
  margin-left: 2%;
  span {
    color: black;
  }
}

.ant-pagination-item{
border-radius: 0 !important;
}


