.sub-headers {
  color: var(--Neutral-zsgray-90, #2f2c3c);
  /* Sub Header/Bold (16) */
  // font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
}

.category-label {
  color: var(--Neutral-zsgray-80, #454250);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.row-class {
  color: var(--Neutral-zsgray-80, #454250);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 150% */
}

.row-class-highlight {
  color: var(--Neutral-zsgray-80, #454250);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 150% */
  background: #FAFAFA;
}

.category-description {
  color: var(--Neutral-zsgray-80, #454250);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.category-span1 {
  width: 20%;
  display: inline-block;
  text-align: left;
}
.category-span2 {
  width: 45%;
  display: inline-block;
  text-align: left;
}
.category-span3 {
  width: 30%;
  display: inline-block;
  text-align: left;
}

.ant-list-item {
  padding: 8px 16px !important;
}

.ant-divider-horizontal {
  margin: 12px 0 !important;
}

.survey-table{
  margin-top: 10 ;
.ant-table-tbody>tr>td{
  vertical-align: baseline !important;
}
}
