.your-component {
  border-left: 4px solid #ccc;
  background: var(--neutral-zsgray-00, #fafafa);
}

.row {
  display: flex;
  background-color: #716e79;
  width: 100%;
  align-items: center;
  margin-top: 0;
  text-align: left;
  color: white;
  padding: 8px 16px;
  gap: 12px;
  font-size: 16px;
  height: 56px;
}

.input-icon-list {
  border-top: 0px;
}

.ant-tag {
  font-size: 14px !important;
}

.drag-header {
  border-radius: 4px 4px 0px 0px;
  background: #d0eaea;
  display: flex;
  padding: 4px 12px !important;

  border: 1px solid var(--primary-zsteal-60, #27a6a4);
  border-bottom: none;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.insights-div {
  padding-top: 16px !important;
}

.unmapped_data {
  border-radius: 4px;
  border: 1px dashed #27a6a4;
  background: #f4f3f3;
  display: flex;
  height: 80px;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  color: #87848d;
  font-size: 14px;
  box-shadow: 0px 2px 0px 0px rgba(26, 22, 40, 0.1);
}

.drag-header-insight {
  padding: 4px 12px !important;

  border-radius: 4px 4px 0px 0px;
  background: var(--neutral-zsgray-20, #dedcde);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--primary-zsteal-60, #27a6a4);
  border-bottom: none;

  background-clip: padding-box;
  align-self: stretch;
}

.abc {
  padding: 12px !important;
}

.question-drag-add {
  border-radius: 0px 0px 4px 4px !important;
  background: #f4f3f3;
  // border: 1px solid var(--primary-zsteal-60, #27A6A4);
  border: 1px solid #27a6a4 !important;
  border-top: none !important;

  // background: var(--primary-zsteal-00, #F3FCFE);
  margin: 0;

  /* shadow-flat-btm-2px */
  box-shadow: 0px 2px 0px 0px rgba(26, 22, 40, 0.1);
}

.question-drag {
  border-radius: 0px 0px 4px 4px;
  background: #d0eaea;
  border: 1px solid var(--primary-zsteal-60, #27a6a4);
  border-top: none;

  background: var(--primary-zsteal-00, #f3fcfe);
  margin: 0;

  /* shadow-flat-btm-2px */
  box-shadow: 0px 2px 0px 0px rgba(26, 22, 40, 0.1);
}

.question-card {
  border-radius: 4px;
  border: 1px solid #dedcde;
  margin: 0;
  background: #f4f3f3;
  box-shadow: 0px 2px 0px 0px rgba(26, 22, 40, 0.1);
}

.question-card:hover {
  border-radius: 4px 4px 4px 4px;
  background: #d0eaea;
  border: 1px solid var(--primary-zsteal-60, #27a6a4);
  background: var(--primary-zsteal-00, #f3fcfe);
  margin: 0;
  box-shadow: 0px 2px 0px 0px rgba(26, 22, 40, 0.1);
}

.question {
  border-radius: 4px;
  border: 1px solid #dedcde;
  background: #fff;
}

.question:hover {
  border-radius: 4px 4px 4px 4px;
  background: #d0eaea;
  border: 1px solid var(--primary-zsteal-60, #27a6a4);
  background: var(--primary-zsteal-00, #f3fcfe);
  margin: 0;
  box-shadow: 0px 2px 0px 0px rgba(26, 22, 40, 0.1);
}

.target-population-style {
  .ant-collapse {
    background-color: #fafafa !important;
  }
}

.catDescp {
  color: var(--Active-electric-70, #0952ca);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.cf-label {
  color: var(--Semantic-Focus-electric-70, #0952ca);
  font-size: 14px;
  line-height: 24px; /* 150% */
}

.cf-label2 {
  color: var(--Semantic-Focus-electric-70, #0952ca);
  word-wrap: break-word;
  word-break: break-word;
  padding-bottom: 8px;
}

.cf-label3 {
  color: var(--Semantic-Focus-electric-70, #0952ca);
  font-size: 16px;
  line-height: 24px;
}

.row-col {
 // padding-bottom: 8px;
}

.row-col {
  .ant-col-24 {
    max-width: 97% !important;
  }
  .ant-col {
    margin-bottom: 8px;
    margin-left: 8px;
  }
}

.div-collapse {
  .ant-collapse {
    background-color: #f8fcff !important;
    border: none;
  }
  .ant-collapse-arrow {
    color: #0952ca !important;
  }
}

.ant-collapse > .ant-collapse-item:last-child {
  margin-bottom: 0px !important;
}

.trgt-pop-fullscreen {
  .ant-card {
    background: #f8fcff !important;
    border: 0.5px solid #2f9bff !important;
    border-radius: 0px !important;
    margin-bottom: 8px !important;
  }
  .ant-card .ant-card-body {
    border-top: 0.5px solid #2f9bff !important;
  }
  .ant-card .ant-card-head {
    padding-left: 8px;
  }
}

.view-close-label {
  color: var(--Primary-zsteal-70, #2d8b93);
  text-align: center;
  font-weight: 400;
}
