.landing-header {
  background: #022d42;
  display: flex;
}

.cards-div {
  display: flex;
  margin-left: 40px;
  margin-top: 30px;
  gap: 16px;
}

:where(.css-dev-only-do-not-override-7bjwhz).ant-card .ant-card-head {
  border-bottom: 0px !important;
}

.landing-card-header {
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
  color: #454250;
}

.brand-name {
  color: #ffffff;
  font-size: 28px;
  font-weight: 700;
  line-height: 48px;
}

.brand-description {
  color: #f4f3f3;
  font-size: 14px;
  font-weight: 400;
  line-height: 25.6px;
}

