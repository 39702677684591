.configure-btn {
  float: left;
  cursor: pointer;
  border-radius: 0;
  height: 40px;
  margin-left: 1.2%;
}

.analysis-btn {
  float: right;
  cursor: pointer;
  background-color: #27a6a4;
  border: 0;
  border-radius: 0;
}

.sub-header {
  font-size: 24px;
  font-style: normal;
  font-weight: bold;
  padding-left: 8px;
  float: left;
}

.ant-input {
  border-radius: 0px;
}

.checbox-style {
  float: left;
  margin-left: 1.2%;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 400;
  color: "#2F2C3C";
}

:where(.css-dev-only-do-not-override-1jr9qlj).ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-title-content,
:where(.css-dev-only-do-not-override-1jr9qlj).ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-title-content {
  flex: inherit;
}

.ant-input-search-button {
  height: 39px !important;
  border-radius: 0 !important;
}

.divider-vertical {
  float: left;
  margin-left: 1.2%;
  height: 40px;
  margin-right: 0px;
}

.ant-dropdown-menu {
  border-radius: 0px !important;

  li {
    border-radius: 0px !important;

    span {
      text-align: left;
    }
  }
}