.ant-notification{
    margin-left: 80px !important;
}
.ant-notification-notice{
    border-radius: 0px !important;
    width: auto !important;
    box-shadow: none !important;
  
}
.ant-notification-notice-icon-success{
    color: #52c41a !important;
}

.ant-notification-notice-success{
    background-color: #f6ffed !important;
    border: 1px solid #b7eb8f;
}

.ant-notification-notice-error{

    background-color: #fff2f0!important;
    border: 1px solid #ffccc7;
}

.ant-notification-notice-info{
    background-color: #e6f4ff!important;
    border: 1px solid #91caff;
    // margin-left: 5% !important;
}

.ant-notification-notice-description {
    margin-inline-start: 0!important;;
}


.ant-notification-notice-message{
    margin-bottom :0px !important;
    font-size: 14px !important;
    max-width: 600px;
}

.ant-notification-notice-with-icon{
    display: flex;
    align-items: center;
    
}

.ant-notification-notice{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ant-notification-notice-close{
    position: unset !important;
}