.ant-badge-status-dot {
  border-radius: 0px !important;
  height: 16px !important;
  width: 16px !important;
}

// .ant-badge {
//   margin-top: 10px;
// }

.ant-btn-primary {
  background-color: #27a6a4 !important;

}
.ant-btn-primary:disabled {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.ant-btn-default:hover {
  border-color: #27a6a4 !important;
  color: #27a6a4 !important;
}

.ant-checkbox-group {
  display: inline-grid !important;
}

.ant-checkbox+span {
  white-space: nowrap !important;
  width: 220px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.horizontal-line {
  width: 98%;
  height: 1px;
  /* Adjust the thickness of the line as needed */
  background-color: #dedcde;
  /* Adjust the line color as needed */
  position: relative;
  margin-left: 8px;
  margin-bottom: 20px;
}

.horizontal-line-rank {
  //width: 87%;
  height: 1px;
  /* Adjust the thickness of the line as needed */
  background-color: #dedcde;
  /* Adjust the line color as needed */
  position: relative;
  margin-left: 8px;
  margin-bottom: 20px;
}

.break {
  position: absolute;
  background-color: #dedcde;
  /* Adjust the break color as needed */
  height: 10px;
  /* Adjust the break height as needed */
  width: 1px;
  /* Adjust the break width as needed */
}

.break:nth-child(1) {
  left: 0%;
  transform: translateX(-50%);
}

.break:nth-child(2) {
  left: 25%;
  transform: translateX(-50%);
}

.break:nth-child(3) {
  left: 50%;
  transform: translateX(-50%);
}

.break:nth-child(4) {
  left: 75%;
  transform: translateX(-50%);
}

.break:nth-child(5) {
  left: 100%;
  transform: translateX(-50%);
}

.break::after {
  content: attr(data-percentage);
  position: absolute;
  top: 20px;
  /* Adjust the distance between the break and the percentage label as needed */
  transform: translateX(-50%);
  font-size: 12px;
  /* Adjust the font size of the percentage label as needed */
}

.percentage {
  position: absolute;
  top: -20px;
  /* Adjust the distance between the break and the percentage label as needed */
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  /* Adjust the font size of the percentage label as needed */
  color: #716e79;
  /* Adjust the color of the percentage label as needed */
}

.ant-dropdown .ant-table-filter-dropdown {
  max-width: 350px !important;
}

.responses{
  // border: 2px solid red;
  max-height: 52vh;//52vh for 10 datasets ;
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Disable horizontal scrolling */
  // scrollbar-width: none;
  // border: 2px solid black;
}

.ant-checkbox + span {
  text-align: left !important;
}

.custom-btn {
  height: 24px;
    padding: 0px 7px;
    border-radius: 4px;
}

.reset-btn {
  margin-right: 8px;
  cursor: not-allowed;
  // background-color: #f5f5f5;
  // border-color: #d9d9d9;
  color: #bfbfbf;
  height: 24px;
  padding: 0px 7px;
}

.reset-btn.enabled {
  cursor: pointer;
  // background-color: orange;
  // border-color: orange;
  color: #ff9900;
}

.reset-btn.enabled:hover {
  color: #ffc552;
}