.maintenance-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
  text-align: center;
  color: #333;
}

.font-weight-light {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.maintain-label {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

// :host {
//   display: block;
//   height: 100%;
//   width: 100%;
// }
.zaidyn-logo {
  width: 10%;
}
