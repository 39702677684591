.title-text {
  align-items: center;
  color: white;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 2.5px;
  padding-left: 8px;
  padding-right: 14px;
  text-transform: uppercase;
  width: max-content;
  // display: inline;
  // margin-top: -12px;
}

.header-text {
  background: #ff9900;
  margin-top: 8px;
  max-width: max-content;
  height: 40px;
  padding: 1px;
  display: flex;
  align-items: center;
}

.header-text1 {
  background: white;
  padding: 0px;
  height: fit-content;
  top: 0 !important;
  z-index: 996;
  padding-bottom: 8px;
  border-bottom: 1px solid #DEDCDE;
  position: sticky;
}

.side-nav-bar {
  flex: 0 0 80px !important;
  max-width: 80px !important;
  min-width: 80px !important;
  width: 80px !important;
  background: #1a1628;
}

li {
  span {
    text-align: center;

    div {
      text-align: center;
    }

    div {
      color: #ffffff;
      font-size: 12px;

      span {
        display: block;
        line-height: 1.5;
      }
    }
  }
}

.side-nav-bar-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  background: #2F2C3C;
  // z-index: 9999;
  height: 100%;
}

#navbar-container .ant-menu-item-active {
  background-color: #716E79;
}

#navbar-container .ant-menu-item {
  height: auto;
  padding: 0;
  border-radius: 0px;
}

#navbar-container .ant-menu-item-selected {
  background-color: #454250;
  border-radius: 0px;
}

.navBarItems {
  margin-top: -10px;
  margin-bottom: 8px;
}

.data-updates-div {
  // #DEDCDE
  border-style: solid;
  border-width: 0 0 0 1px;
  border-color: #dedcde;
  width: 164px;
  float: right;
  height: 56px;
  cursor: pointer;
}

.data-text {
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
}

.findings-report-sider {
  // width: 370px!important;
  max-width: 100% !important;
  min-width: 370px !important;
  width: 100% !important;
  background-color: #fafafa !important;
  border-left: 4px solid #b2b0b6;
  z-index: 1001;
}

.dropdown-link {
  color: #1a1628 !important;
}

.ant-layout-content{
  border-top: none !important;
}

.ant-layout-sider-children{
  background: #2F2C3C;
}

.logo-container{
  margin-top: 6px;
  margin-right: 16px;
}

.logo-separator {
  margin: 0 8px;
  font-size: 24px;
  vertical-align: middle;
  
}
